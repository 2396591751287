import Swagger from 'swagger-client';

let clientInternal = undefined;

export const configureSwaggerClient = () => {
	return Swagger('/swagger.json').then((client) => {
		if (client.errors.length > 0) {
			console.log(client.errors);
		}
		clientInternal = client;
	});
};

export const swaggerClient = () => {
	return clientInternal;
};

export const loginWithFB = async (token) => {
	if (!clientInternal) {
		await configureSwaggerClient();
	}

	try {
		let result = await clientInternal.apis.login.register({
			'auth-type': 0,
			token: token });
		return JSON.parse(result.data);
	} catch (err) {
		errorHandler(err);
	}
};

export const loginWithCredentials = async (email, password, otp) => {
	if (!clientInternal) {
		await configureSwaggerClient();
	}

	try {
		let result = await clientInternal.apis.login.login({
			email: email,
			password: password,
			otp: otp
		});
		return JSON.parse(result.data);
	} catch (err) {
		console.log(err);
		errorHandler(err);
	}
};

export const verifySession = async () => {
	if (!clientInternal) {
		await configureSwaggerClient();
	}

	try {
		let result = await clientInternal.apis.login.verify_session();
		return JSON.parse(result.data);
	} catch (err) {
		errorHandler(err);
	}
};

export const sendOTP = async (email) => {
	if (!clientInternal) {
		await configureSwaggerClient();
	}

	try {
		await clientInternal.apis.login.send_otp({ email: email });
	} catch (err) {
		errorHandler(err);
	}
};

export const SignupResponse = Object.freeze({
	success: 1,
	needsOTP: 2,
	goToSetPassword: 3,
	error: 4
});
export const signup = async (name, email, password, otp) => {
	if (!clientInternal) {
		await configureSwaggerClient();
	}

	try {
		await clientInternal.apis.login.signup({
			name: name,
			password: password,
			email: email,
			otp: otp
		});
		return SignupResponse.success;
	} catch (err) {
		console.log(err);
		if (err.status === 409) {
			return SignupResponse.goToSetPassword;
		} else if (err.status === 425) {
			return SignupResponse.needsOTP;
		} else {
			let response = JSON.parse(err.response.text);
			if (response && response.message) {
				return response.message;
			}
			return SignupResponse.error;
		}
	}
};

export const errorHandler = (err) => {
	if (err.message) {
		throw new Error(err.message);
	}

	let response = JSON.parse(err.response.text);
	if (response && response.message) {
		throw new Error(response.message);
	}

	throw new Error('Server Error. Try again.');
};
