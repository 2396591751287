import { ContactsTwoTone, LockTwoTone, MailTwoTone } from '@ant-design/icons';
import { Button, Card, Input, Space } from 'antd';
import PropTypes from 'prop-types';
import { Component } from 'react';
import '../styles/Login.sass';
import { ReactComponent as LoginKameroSVG } from '../svg/kamero_only_icon.svg';
import { sendOTP, signup, SignupResponse } from '../swagger';
import FBLoginComp from './FBLoginComp';

const CardType = Object.freeze({ login: 1, willSendOTP: 2, setPassword: 3, signup: 4 });
class LoginComp extends Component {
	constructor(props) {
		super(props);
		this.state = {
			localError: undefined,
			localLoading: false,
			email: undefined,
			name: '',
			password: undefined,
			confirmPassword: undefined,
			cardType: CardType.login,
			otp: undefined,
			needsOTPToSignup: false,
			isMobile: window.screen.width <= 576
		};
		this.handleWindowResize = this.handleWindowResize.bind(this);
	}

	componentDidMount() {
		window.addEventListener('resize', this.handleWindowResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowResize);
	}

	handleWindowResize = async () => {
		if (window.innerWidth <= 576 && !this.state.isMobile) this.setState({ isMobile: true });
		else if (window.innerWidth > 576 && this.state.isMobile) this.setState({ isMobile: false });
	};

	error() {
		const { lastError } = this.props;
		return this.state.localError || lastError ? (
			<p style={{ color: '#FF7981', fontSize: '12px' }}>
				{lastError ? lastError : this.state.localError}
			</p>
		) : null;
	}

	changeCard(cardType) {
		const { clearLoginErr } = this.props;
		this.setState({
			cardType: cardType,
			localError: undefined,
			localLoading: false,
			needsOTPToSignup: false,
			otp: undefined
		});
		clearLoginErr();
	}

	loginCard() {
		const { isLogging, login } = this.props;

		if (this.state.isMobile) {
			return (
				<Card className="login-card" bordered={false}>
					<div
						role="button"
						style={{ marginBottom: 20, width: '100%', display: 'flex', alignItems: 'center' }}>
						<div style={{ borderBottom: '1.5px solid #E40949', padding: '0px 20px', flex: 1 }}>
							<h5
								style={{
									fontSize: '18px',
									color: '#6F4898',
									textAlign: 'center',
									fontWeight: 400
								}}>
								Log In
							</h5>
						</div>
						<div
							role="button"
							style={{ padding: '0px 20px', borderBottom: '1.5px solid #dce4ec', flex: 1 }}
							onClick={() => {
								this.changeCard(CardType.signup);
							}}>
							<h5
								style={{
									fontSize: '18px',
									color: '#27272A',
									textAlign: 'center',
									fontWeight: 400
								}}>
								Sign Up
							</h5>
						</div>
					</div>
					<Space direction="vertical">
						<FBLoginComp />
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<hr style={{ flex: 1 }} />
							<p style={{ color: '#777', margin: '0px 8px' }}>OR</p>
							<hr style={{ flex: 1 }} />
						</div>
						<Input
							name="username"
							placeholder="Enter your Email Id"
							prefix={<MailTwoTone />}
							onChange={(e) => {
								this.setState({ email: e.target.value });
							}}
						/>
						<Input.Password
							placeholder="Enter your Password"
							prefix={<LockTwoTone />}
							onChange={(e) => {
								this.setState({ password: e.target.value });
							}}
						/>
						{this.error()}
						<Button
							type="primary"
							style={{
								width: '100%',
								height: '100%',
								marginTop: '16px',
								fontSize: '16px',
								fontWeight: 400
							}}
							loading={isLogging}
							onClick={async (e) => {
								if (this.state.email && this.state.password) {
									await login(undefined, this.state.email, this.state.password);
								} else {
									this.setState({ localError: 'Enter valid email and password.' });
								}
							}}>
							Log In
						</Button>
						<Button
							type="link"
							onClick={async (e) => {
								this.changeCard(CardType.willSendOTP);
							}}>
							Forgot password?
						</Button>
					</Space>
				</Card>
			);
		}

		return (
			<Card className="login-card" title={<h3>Login to Kamero</h3>} bordered={false}>
				<p>Login with your facebook account or email</p>
				<FBLoginComp />
				<p style={{ color: '#777' }}>or</p>
				<Space direction="vertical" size="small">
					<Input
						name="username"
						placeholder="Email"
						prefix={<MailTwoTone />}
						onChange={(e) => {
							this.setState({ email: e.target.value });
						}}
					/>
					<Input.Password
						placeholder="Password"
						prefix={<LockTwoTone />}
						onChange={(e) => {
							this.setState({ password: e.target.value });
						}}
					/>
					{this.error()}
					<Button
						type="primary"
						style={{ width: '150px', marginTop: '16px' }}
						loading={isLogging}
						onClick={async (e) => {
							if (this.state.email && this.state.password) {
								await login(undefined, this.state.email, this.state.password);
							} else {
								this.setState({ localError: 'Enter valid email and password.' });
							}
						}}>
						Login
					</Button>
					<Button
						type="link"
						onClick={async (e) => {
							this.changeCard(CardType.willSendOTP);
						}}>
						Forgot password?
					</Button>
					<hr style={{ color: '#EEE' }} />
					<div style={{ color: '#666' }}>
						New to kamero?{' '}
						<a
							type="link"
							onClick={() => {
								this.changeCard(CardType.signup);
							}}>
							Sign up
						</a>
						now
					</div>
				</Space>
			</Card>
		);
	}

	willSendOTPCard() {
		const { isLogging } = this.props;
		return (
			<Card className="login-card" title={<h3>Verify email by OTP</h3>} bordered={false}>
				<p>Enter email to get an OTP.</p>
				<Space direction="vertical" size="small">
					<Input
						name="username"
						placeholder="Email"
						prefix={<MailTwoTone />}
						onChange={(e) => {
							this.setState({ email: e.target.value });
						}}
					/>
					{this.error()}
					<Button
						type="primary"
						style={{ width: '150px', marginTop: '16px' }}
						loading={isLogging || this.state.localLoading}
						onClick={async (e) => {
							if (this.state.email) {
								try {
									this.setState({ localLoading: true });
									await sendOTP(this.state.email, this.state.password);
									this.changeCard(CardType.setPassword);
								} catch (e) {
									this.setState({ localError: e.message });
								}
							} else {
								this.setState({ localError: 'Enter valid email.' });
							}
						}}>
						Send OTP
					</Button>
					<Button
						type="link"
						onClick={async (e) => {
							this.changeCard(CardType.login);
						}}>
						Back to Login
					</Button>
				</Space>
			</Card>
		);
	}

	setPasswordCard() {
		const { isLogging, login } = this.props;
		return (
			<Card className="login-card" title={<h3>"Set password"</h3>} bordered={false}>
				<p>Reset your password using OTP received on your email.</p>
				<Space direction="vertical" size="small">
					<Input
						name="username"
						placeholder="Email"
						prefix={<MailTwoTone />}
						disabled={true}
						value={this.state.email}
					/>
					<Input
						placeholder="Enter OTP"
						prefix={<MailTwoTone />}
						onChange={(e) => {
							this.setState({ otp: e.target.value });
						}}
					/>
					<Input.Password
						placeholder="Password"
						prefix={<LockTwoTone />}
						onChange={(e) => {
							this.setState({ password: e.target.value });
						}}
					/>
					<Input.Password
						placeholder="Confirm Password"
						prefix={<LockTwoTone />}
						onChange={(e) => {
							this.setState({ confirmPassword: e.target.value });
						}}
					/>
					{this.error()}
					<Button
						type="primary"
						style={{ width: '150px', marginTop: '16px' }}
						loading={isLogging || this.state.localLoading}
						onClick={async (e) => {
							if (!this.state.otp) {
								this.setState({ localError: 'Please enter OTP' });
							} else if (this.state.password !== this.state.confirmPassword) {
								this.setState({ localError: 'Passwords do not match' });
							} else {
								await login(undefined, this.state.email, this.state.password, this.state.otp);
							}
						}}>
						Set Password
					</Button>
					<Button
						type="link"
						onClick={async (e) => {
							this.changeCard(CardType.login);
						}}>
						Back to Login
					</Button>
				</Space>
			</Card>
		);
	}

	signupCard() {
		const { isLogging, login } = this.props;

		if (this.state.isMobile) {
			return (
				<Card className="login-card" bordered={false}>
					<div style={{ marginBottom: 20, width: '100%', display: 'flex', alignItems: 'center' }}>
						<div
							role="button"
							style={{ padding: '0px 20px', borderBottom: '1.5px solid #dce4ec', flex: 1 }}
							onClick={() => {
								this.changeCard(CardType.login);
							}}>
							<h5
								style={{
									fontSize: '18px',
									color: '#27272A',
									textAlign: 'center',
									fontWeight: 400
								}}>
								Log In
							</h5>
						</div>
						<div
							role="button"
							style={{ borderBottom: '1.5px solid #E40949', padding: '0px 20px', flex: 1 }}>
							<h5
								style={{
									fontSize: '18px',
									color: '#6F4898',
									textAlign: 'center',
									fontWeight: 400
								}}>
								Sign Up
							</h5>
						</div>
					</div>
					<Space direction="vertical">
						<FBLoginComp />
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<hr style={{ flex: 1 }} />
							<p style={{ color: '#777', margin: '0px 8px' }}>OR</p>
							<hr style={{ flex: 1 }} />
						</div>
						<Input
							name="name"
							placeholder="Enter your name"
							prefix={<ContactsTwoTone />}
							value={this.state.name}
							disabled={this.state.needsOTPToSignup}
							onChange={(e) => {
								this.setState({ name: e.target.value });
							}}
						/>
						<Input
							name="username"
							placeholder="Enter your Email Id"
							prefix={<MailTwoTone />}
							value={this.state.email}
							disabled={this.state.needsOTPToSignup}
							onChange={(e) => {
								this.setState({ email: e.target.value });
							}}
						/>
						<Input.Password
							name="password"
							placeholder="Enter your Password"
							prefix={<LockTwoTone />}
							disabled={this.state.needsOTPToSignup}
							onChange={(e) => {
								this.setState({ password: e.target.value });
							}}
						/>
						<Input.Password
							placeholder="Confirm Password"
							prefix={<LockTwoTone />}
							disabled={this.state.needsOTPToSignup}
							onChange={(e) => {
								this.setState({ confirmPassword: e.target.value });
							}}
						/>

						{this.state.needsOTPToSignup ? (
							<div>
								<p>Please verify email by OTP received on your email.</p>
								<Input
									placeholder="Enter OTP"
									prefix={<MailTwoTone />}
									onChange={(e) => {
										this.setState({ otp: e.target.value });
									}}
								/>
							</div>
						) : null}
						{this.error()}
						<Button
							type="primary"
							style={{
								margin: '16px 0px',
								width: '100%',
								height: '100%',
								fontSize: '16px',
								fontWeight: 400
							}}
							loading={isLogging || this.state.localLoading}
							onClick={async (e) => {
								if (
									!this.state.name ||
									!this.state.email ||
									!this.state.password ||
									!this.state.confirmPassword
								) {
									this.setState({ localError: 'Please enter all details.' });
								} else if (this.state.password !== this.state.confirmPassword) {
									this.setState({ localError: 'Passwords do not match' });
								} else {
									if (this.state.needsOTPToSignup) {
										if (!this.state.otp) {
											this.setState({ localError: 'Please enter OTP.' });
										} else {
											this.setState({ localLoading: true });
											await login(undefined, this.state.email, this.state.password, this.state.otp);
										}
									} else {
										this.setState({ localLoading: true });
										let response = await signup(
											this.state.name,
											this.state.email,
											this.state.password
										);
										this.handleSignupResponse(response);
									}
								}
							}}>
							Create Kamero Account
						</Button>
					</Space>
					<div
						style={{
							fontSize: '16px',
							fontWeight: 400,
							marginBottom: '10px'
						}}>
						By creating an account, you accept our
						<br />
						<div
							className="anchorDiv"
							onClick={(e) => {
								e.preventDefault();
								window.open('https://kamero.in/termsofuse.html', '_blank');
							}}>
							terms & conditions
						</div>{' '}
						and{' '}
						<span
							className="anchorDiv"
							onClick={(e) => {
								e.preventDefault();
								window.open('https://kamero.in/privacypolicy.html', '_blank');
							}}>
							privacy policy
						</span>
					</div>

					{/* <p>
						By creating an account,you accept our <br />
						<span style={{ alignItems: 'center', color: '#6F4898' }}>
							<b>terms & conditions</b> <span style={{ color: 'black' }}>and</span>{' '}
							<b>privacy policy</b>
						</span>
					</p> */}
				</Card>
			);
		}

		return (
			<Card className="login-card" title={<h3>Sign up</h3>} bordered={false}>
				<p>Enter below details to create new account.</p>
				<Space direction="vertical" size="small">
					<Input
						name="name"
						placeholder="Your name"
						prefix={<ContactsTwoTone />}
						value={this.state.name}
						disabled={this.state.needsOTPToSignup}
						onChange={(e) => {
							this.setState({ name: e.target.value });
						}}
					/>
					<Input
						name="username"
						placeholder="Email"
						prefix={<MailTwoTone />}
						value={this.state.email}
						disabled={this.state.needsOTPToSignup}
						onChange={(e) => {
							this.setState({ email: e.target.value });
						}}
					/>
					<Input.Password
						name="password"
						placeholder="Password"
						prefix={<LockTwoTone />}
						disabled={this.state.needsOTPToSignup}
						onChange={(e) => {
							this.setState({ password: e.target.value });
						}}
					/>
					<Input.Password
						placeholder="Confirm Password"
						prefix={<LockTwoTone />}
						disabled={this.state.needsOTPToSignup}
						onChange={(e) => {
							this.setState({ confirmPassword: e.target.value });
						}}
					/>

					{this.state.needsOTPToSignup ? (
						<div>
							<p>Please verify email by OTP received on your email.</p>
							<Input
								placeholder="Enter OTP"
								prefix={<MailTwoTone />}
								onChange={(e) => {
									this.setState({ otp: e.target.value });
								}}
							/>
						</div>
					) : null}
					{this.error()}
					<Button
						type="primary"
						style={{ width: '150px', marginTop: '16px' }}
						loading={isLogging || this.state.localLoading}
						onClick={async (e) => {
							if (
								!this.state.name ||
								!this.state.email ||
								!this.state.password ||
								!this.state.confirmPassword
							) {
								this.setState({ localError: 'Please enter all details.' });
							} else if (this.state.password !== this.state.confirmPassword) {
								this.setState({ localError: 'Passwords do not match' });
							} else {
								if (this.state.needsOTPToSignup) {
									if (!this.state.otp) {
										this.setState({ localError: 'Please enter OTP.' });
									} else {
										this.setState({ localLoading: true });
										await login(undefined, this.state.email, this.state.password, this.state.otp);
									}
								} else {
									this.setState({ localLoading: true });
									let response = await signup(
										this.state.name,
										this.state.email,
										this.state.password
									);
									this.handleSignupResponse(response);
								}
							}
						}}>
						Signup
					</Button>
					<Button
						type="link"
						onClick={async (e) => {
							this.changeCard(CardType.login);
						}}>
						Back to Login
					</Button>
				</Space>
			</Card>
		);
	}

	handleSignupResponse(response) {
		if (typeof response === 'string') {
			this.setState({ localError: response, localLoading: false });
			return;
		}

		switch (response) {
			case SignupResponse.success: {
				this.setState({ localError: undefined, localLoading: false });
				break;
			}
			case SignupResponse.needsOTP: {
				this.setState({ needsOTPToSignup: true, localError: undefined, localLoading: false });
				break;
			}
			case SignupResponse.goToSetPassword: {
				this.setState({ needsOTPToSignup: true, localError: undefined, localLoading: false });
				break;
			}
			case SignupResponse.error: {
				this.setState({ localError: 'Error occured. Please try again.', localLoading: false });
				break;
			}
		}
	}

	render() {
		return (
			<div
				className="flexCenterPageContainer login-bg"
				style={{ minHeight: 800, alignItems: this.state.isMobile ? 'flex-start' : 'center' }}>
				<div
					className="flexCenterPageChild"
					style={this.state.isMobile ? { marginTop: '25%' } : {}}>
					{this.state.isMobile ? (
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								marginBottom: '20px'
							}}>
							<LoginKameroSVG style={{ height: '50px', width: '50px' }} />
							<p className="kamero-brand-text" style={{ margin: '10px 0px 0px 10px', padding: 0 }}>
								Kamero
							</p>
						</div>
					) : (
						<>
							<LoginKameroSVG className="loginKameroIcon" />
							<p className="kamero-brand-text">Kamero</p>
							<h2 className="login-sharing-text">Ai Powered Photo Sharing For Events</h2>
						</>
					)}

					{(() => {
						switch (this.state.cardType) {
							case CardType.login:
								return this.loginCard();
							case CardType.willSendOTP:
								return this.willSendOTPCard();
							case CardType.setPassword:
								return this.setPasswordCard();
							case CardType.signup:
								return this.signupCard();
						}
					})()}
					<div style={{ height: '150px' }} />
				</div>
			</div>
		);
	}
}

LoginComp.propTypes = {
	isLogging: PropTypes.bool.isRequired,
	lastError: PropTypes.string,
	login: PropTypes.func.isRequired,
	clearLoginErr: PropTypes.func.isRequired
};

export default LoginComp;
