import { Component } from 'react';
import PropTypes from 'prop-types';
import PhotoComp from './PhotoComp';
import SortBySetting from '../containers/SortBySetting';
import {
	CheckSquareOutlined,
	CloseSquareOutlined,
	DeleteOutlined,
	WarningOutlined
} from '@ant-design/icons';
import { Button, Select, Pagination, Popconfirm, message, Spin } from 'antd';
import Uploads from '../containers/Uploads';

const { Option } = Select;

class PhotoListComp extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			inSelectMode: false,
			selectedPhotos: new Set(),
			isMobile: window.screen.width <= 576
		};
		this.handleWindowResize = this.handleWindowResize.bind(this);
	}

	componentDidMount() {
		window.addEventListener('resize', this.handleWindowResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowResize);
	}

	handleWindowResize = async () => {
		if (window.innerWidth <= 576 && !this.state.isMobile) this.setState({ isMobile: true });
		else if (window.innerWidth > 576 && this.state.isMobile) this.setState({ isMobile: false });
	};

	moveToAlbum = async (albumDocId) => {
		console.log(albumDocId, 'albumDocId');
		const { clickedEvent, clickedAlbum, movePhotos } = this.props;
		try {
			this.setState({ loading: true });
			await movePhotos(clickedEvent, clickedAlbum, albumDocId, [...this.state.selectedPhotos]);
			this.setState({ loading: false, selectedPhotos: new Set(), inSelectMode: false });
			message.success('Photos are moved!');
		} catch (e) {
			message.error(e.response.body.message);
			this.setState({ loading: false });
		}
	};

	deleteSelectedPhotos = async () => {
		const { clickedEvent, clickedAlbum, deletePhotos } = this.props;
		try {
			this.setState({ loading: true });
			await deletePhotos(clickedEvent, clickedAlbum, [...this.state.selectedPhotos]);
			this.setState({ loading: false, selectedPhotos: new Set(), inSelectMode: false });
			message.success('Photos are deleted!');
		} catch (e) {
			message.error(e.response.body.message);
			this.setState({ loading: false });
		}
	};

	deletePopOver = (
		<div>
			<p>Are you sure?</p>
			<p>This will permanently delete this photo(s).</p>
		</div>
	);

	getActionComp = () => {
		const { photos, visibleAlbums, clickedAlbum } = this.props;
		if (photos?.length > 0) {
			let moveableAlbums = [];
			if (clickedAlbum?.id && visibleAlbums?.length > 0)
				moveableAlbums = visibleAlbums?.filter((album) => album.id !== clickedAlbum.id);
			return (
				<div>
					{this.state.selectedPhotos.size > 0 &&
					moveableAlbums.length > 0 &&
					!clickedAlbum?.isGuestUploads ? (
						<Select
							placeholder="Move to.."
							disabled={this.state.loading}
							loading={this.state.loading}
							style={{ width: '120px', marginRight: '8px' }}
							onChange={this.moveToAlbum}>
							{moveableAlbums.map((album, index) => (
								<Option key={index} value={album.id} title={null}>
									{album.name}
								</Option>
							))}
						</Select>
					) : null}
					{this.state.selectedPhotos?.size > 0 ? (
						<Popconfirm
							title={this.deletePopOver}
							icon={<WarningOutlined />}
							onConfirm={this.deleteSelectedPhotos}
							onCancel={(e) => {
								e.stopPropagation();
							}}
							okText="Delete Anyway!"
							cancelText="Cancel">
							<Button disabled={this.state.loading} loading={this.state.loading}>
								Delete
							</Button>
						</Popconfirm>
					) : null}
					<Button
						disabled={this.state.loading}
						style={{ marginRight: '20px', marginLeft: '8px', width: '75px' }}
						onClick={(e) => {
							e.preventDefault();
							this.setState({ inSelectMode: !this.state.inSelectMode });
							if (this.state.inSelectMode) {
								this.setState({ selectedPhotos: new Set() });
							}
						}}>
						{this.state.inSelectMode ? 'Cancel' : 'Select'}
					</Button>
				</div>
			);
		}
		return null;
	};

	getPaginationComp = () => {
		const {
			clickedEvent,
			clickedAlbum,
			currentPage,
			currentAlbumPhotoCount,
			photoPerPage,
			photos,
			pageUpdated,
			perPagePhotoCountChanged
		} = this.props;

		if (photos?.length > 0) {
			return (
				<Pagination
					total={currentAlbumPhotoCount}
					pageSize={photoPerPage}
					current={currentPage}
					showSizeChanger
					pageSizeOptions={['50', '500', '1000', '2000']}
					onShowSizeChange={(_, pageSize) => {
						perPagePhotoCountChanged(pageSize);
					}}
					onChange={(page, _) => {
						pageUpdated(clickedEvent, clickedAlbum, page);
					}}
				/>
			);
		}

		return null;
	};

	getPhotoGridComp = () => {
		const { clickedEvent, photos, onPhotoClick, logoutNow, albumIndex, currentAlbumPhotoCount } =
			this.props;

		if (photos?.length === 0 && currentAlbumPhotoCount > 0)
			return (
				<div
					style={{
						margin: '10px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}>
					<Spin />
				</div>
			);

		return (
			<div className="photos-grid">
				{photos?.map((photo, index) => (
					<PhotoComp
						className="card photos-grid-item"
						key={photo.id}
						isSelected={this.state.selectedPhotos.has(photo.id)}
						photo={photo}
						isThumbnail
						onClick={() => {
							if (this.state.inSelectMode) {
								console.log(this.state.selectedPhotos);
								let _selectedPhotos = new Set(this.state.selectedPhotos);
								if (this.state.selectedPhotos.has(photo.id)) {
									_selectedPhotos.delete(photo.id);
								} else {
									_selectedPhotos.add(photo.id);
								}
								this.setState({ selectedPhotos: _selectedPhotos });
							} else {
								onPhotoClick(index, albumIndex, clickedEvent.id);
							}
						}}
						logoutNow={() => {
							logoutNow();
						}}
					/>
				))}
				{[1, 2, 3, 4, 5].map((value) => (
					<div className="photos-grid-item-extra" key={value}></div>
				))}
			</div>
		);
	};

	render() {
		const { className, clickedAlbum, currentAlbumPhotoCount } = this.props;

		return (
			<div
				className={this.state.isMobile ? '' : className}
				style={this.state.isMobile ? { marginTop: '20px' } : {}}>
				{this.state.isMobile && (
					<div
						style={{
							margin: '10px',
							display: 'flex',
							flexWrap: 'wrap',
							alignItems: 'center',
							justifyContent: 'center'
						}}>
						<h2
							style={{
								margin: '10px 0px',
								color: '#3F3F46',
								fontWeight: 700,
								marginRight: '10px',
								fontSize: '20px'
							}}>
							{`${clickedAlbum?.name} (${currentAlbumPhotoCount || 0})`}
						</h2>
						<div style={{ flex: 1 }} />
						<SortBySetting />
						{this.state.inSelectMode ? (
							<>
								<CloseSquareOutlined
									style={{ fontSize: '1.5rem', marginLeft: '10px' }}
									onClick={(e) => {
										this.setState({ inSelectMode: false, selectedPhotos: new Set() });
									}}
								/>
								{this.state.selectedPhotos.size > 0 ? (
									<Popconfirm
										title={this.deletePopOver}
										icon={<WarningOutlined />}
										onConfirm={this.deleteSelectedPhotos}
										onCancel={(e) => {
											e.stopPropagation();
										}}
										okText="Delete Anyway!"
										cancelText="Cancel">
										<DeleteOutlined style={{ fontSize: '1.5rem', marginLeft: '10px' }} />
									</Popconfirm>
								) : null}
							</>
						) : (
							<CheckSquareOutlined
								style={{ fontSize: '1.5rem', marginLeft: '10px' }}
								onClick={(e) => {
									this.setState({ inSelectMode: true });
								}}
							/>
						)}
					</div>
				)}
				{!clickedAlbum?.isGuestUploads && <Uploads />}
				{!this.state.isMobile && (
					<div className="event-home-photos-title">
						<div className="event-home-photos-title-name">
							<h2 style={{ marginBottom: '2px', marginTop: '2px' }}>{clickedAlbum?.name}</h2>
							{currentAlbumPhotoCount !== undefined ? (
								currentAlbumPhotoCount > 0 ? (
									<p style={{ margin: '0px' }}>{currentAlbumPhotoCount} photos</p>
								) : (
									<p style={{ margin: '0px' }}>No photos</p>
								)
							) : (
								<p></p>
							)}
						</div>
						{this.getActionComp()}
						{this.getPaginationComp()}
					</div>
				)}
				{this.getPhotoGridComp()}
				{this.state.isMobile && (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							marginTop: '20px'
						}}>
						{this.getPaginationComp()}
					</div>
				)}
			</div>
		);
	}
}

PhotoListComp.propTypes = {
	photos: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired
		}).isRequired
	).isRequired,
	currentPage: PropTypes.number.isRequired,
	onPhotoClick: PropTypes.func.isRequired,
	pageUpdated: PropTypes.func.isRequired,
	photoPerPage: PropTypes.number.isRequired,
	currentAlbumPhotoCount: PropTypes.number,
	clickedEvent: PropTypes.shape({
		name: PropTypes.string.isRequired
	}).isRequired,
	clickedAlbum: PropTypes.shape({
		name: PropTypes.string.isRequired
	}).isRequired,
	guestUpload: PropTypes.func.isRequired,
	albumIndex: PropTypes.number.isRequired,
	logoutNow: PropTypes.func.isRequired,
	perPagePhotoCountChanged: PropTypes.func.isRequired,
	visibleAlbums: PropTypes.array,
	deletePhotos: PropTypes.func.isRequired,
	movePhotos: PropTypes.func.isRequired
};

export default PhotoListComp;
