import React, { useEffect, useState, useRef } from 'react';
import { ColorPicker, Upload, Button, Row, Col, Switch } from 'antd';
import { UploadOutlined, CloseOutlined } from '@ant-design/icons';
import QRCodeStyling from 'qr-code-styling';
import Profile from '../pouchDB/models/Profile';

import defaultLogoImage from '../svg/kamero_logo.svg';
const defaultQRColor = '#6F4898';

const QRCodeGenerator = ({ data, auth, eventName }) => {
	const [qrCodeDataURL, setQrCodeDataURL] = useState('');
	const qrCodeRef = useRef(null);
	const [logoSrc, setLogoSrc] = useState(defaultLogoImage);
	const [qrColor, setQrColor] = useState(defaultQRColor);
	const [showLogo, setShowLogo] = useState(true);
	const [customLogoFile, setCustomLogoFile] = useState(null);
	const [profileData, setProfileData] = useState(null);

	const handleCustomLogoUpload = (file) => {
		setCustomLogoFile(file);
		setShowLogo(true);
	};

	useEffect(() => {
		let isSubscribed = true;

		const fetchProfileData = async () => {
			try {
				const profile = await Profile.fetchProfile(auth.userId);

				if (isSubscribed) {
					setProfileData(profile);
				}
			} catch (err) {
				console.error('Failed to fetch profile data:', err);
			}
		};

		fetchProfileData();

		return () => {
			isSubscribed = false;
		};
	}, [auth.userId]);

	const handleLogoVisibilityChange = (checked) => {
		setShowLogo(checked);
	};

	useEffect(() => {
		const fetchLogoImage = async () => {
			let logoSrc = defaultLogoImage;

			if (customLogoFile) {
				logoSrc = URL.createObjectURL(customLogoFile);
			} else if (profileData && profileData.profilePhoto) {
				try {
					const response = await fetch(profileData.profilePhoto);
					if (response.ok) {
						logoSrc = profileData.profilePhoto;
					} else {
						console.warn('Failed to fetch logo image');
					}
				} catch (error) {
					console.error('Error fetching logo image:', error);
				}
			}

			setLogoSrc(logoSrc);
		};

		fetchLogoImage();
	}, [customLogoFile, profileData]);

	useEffect(() => {
		const setQRCodeColor = () => {
			let qrColor = defaultQRColor;

			if (customLogoFile && profileData && profileData.brandColor) {
				qrColor = profileData.brandColor;
			} else if (profileData && profileData.brandColor) {
				qrColor = profileData.brandColor;
			}

			setQrColor(qrColor);
		};

		setQRCodeColor();
	}, [profileData]);

	useEffect(() => {
		const fetchQRCodeData = async () => {
			if (data && logoSrc && qrColor) {
				const qrCodeOptions = {
					width: 250,
					height: 250,
					data: data,
					image: showLogo ? logoSrc : undefined,
					dotsOptions: {
						color: qrColor,
						type: 'dots'
					},
					cornersSquareOptions: {
						type: 'extra-rounded'
					},
					imageOptions: {
						crossOrigin: 'anonymous',
						imageSize: 0.5,
						margin: 4
					}
				};

				const qrCode = new QRCodeStyling(qrCodeOptions);
				const qrCodeBlob = await qrCode.getRawData(data);
				const qrCodeDataUrl = await blobToDataURL(qrCodeBlob);
				setQrCodeDataURL(qrCodeDataUrl);
			}
		};

		fetchQRCodeData();
	}, [data, logoSrc, qrColor, showLogo]);

	const blobToDataURL = (blob) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onloadend = () => resolve(reader.result);
			reader.onerror = reject;
			reader.readAsDataURL(blob);
		});
	};

	const renderQRCode = () => {
		if (qrCodeDataURL) {
			return (
				<div
					ref={qrCodeRef}
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						marginTop: '20px'
					}}>
					<img src={qrCodeDataURL} alt="QR Code" width="250" height="250" />
				</div>
			);
		}
		return null;
	};

	const onDownloadClick = () => {
		if (data && logoSrc && qrColor) {
			const qrCodeOptions = {
				width: 4096,
				height: 4096,
				data: data,
				image: showLogo ? logoSrc : undefined,
				dotsOptions: {
					color: qrColor,
					type: 'dots'
				},
				cornersSquareOptions: {
					type: 'extra-rounded'
				},
				imageOptions: {
					crossOrigin: 'anonymous',
					imageSize: 0.5,
					margin: 35
				}
			};

			const qrCode = new QRCodeStyling(qrCodeOptions);
			qrCode.download({
				name: `${eventName}_QR_Code`,
				extension: 'svg'
			});
			qrCode.download({
				name: `${eventName}_QR_Code`,

				extension: 'png'
			});
		}
	};

	return (
		<div>
			{renderQRCode()}

			<Row gutter={16} align="center" style={{ marginTop: '10px' }}>
				<Upload
					accept="image/*"
					beforeUpload={(file) => {
						handleCustomLogoUpload(file);
						return false; // Prevent upload to server
					}}
					showUploadList={false}>
					<Button icon={<UploadOutlined />}>
						{customLogoFile ? customLogoFile.name : 'Upload Custom Logo'}
					</Button>
				</Upload>

				{customLogoFile && (
					<Button
						icon={<CloseOutlined />}
						onClick={() => {
							setCustomLogoFile(null);
						}}
						style={{ marginLeft: '10px' }}></Button>
				)}
			</Row>

			<Row gutter={16} align="space-between" style={{ marginTop: '10px' }}>
				<Col>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<h5 style={{ marginRight: '8px' }}>QR Code Color:</h5>
						<ColorPicker
							value={qrColor}
							onChange={(_, hex) => {
								setQrColor(hex);
							}}
							showText
						/>
					</div>
				</Col>
				<Col>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<h5 style={{ marginRight: '8px' }}>Show Logo:</h5>
						<Switch checked={showLogo} onChange={handleLogoVisibilityChange} />
					</div>
				</Col>
			</Row>

			<Row gutter={16} align="center" style={{ marginTop: '14px' }}>
				<Button type="primary" onClick={onDownloadClick}>
					Download QR Code
				</Button>
			</Row>
		</div>
	);
};

export default QRCodeGenerator;
