import { Col, message } from 'antd';
import { SquareLibrary } from 'lucide-react';
import PropTypes from 'prop-types';
import React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import NameEditDeleteItemComp from './NameEditDeleteItemComp';
import TextFieldButtonComp from './TextFieldButtonComp';

const ALBUM_NAME_LENGTH = 20;

class AlbumListComp extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isMobile: window.screen.width <= 576
		};
		this.handleWindowResize = this.handleWindowResize.bind(this);
	}

	componentDidMount() {
		window.addEventListener('resize', this.handleWindowResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowResize);
	}

	handleWindowResize = async () => {
		if (window.innerWidth <= 576 && !this.state.isMobile) this.setState({ isMobile: true });
		else if (window.innerWidth > 576 && this.state.isMobile) this.setState({ isMobile: false });
	};

	SortableAlbums = (albums) => {
		let { visibleEvent, clickedAlbum, albumNameEdited, deleteAlbum, deletingAlbums, onAlbumClick } =
			this.props;
		const SortableItem = SortableElement(({ album, sortIndex }) => (
			<div
				className="sortable-row-item"
				onClick={(e) => {
					onAlbumClick(visibleEvent, album);
				}}
				style={{ marginTop: '10px' }}>
				<NameEditDeleteItemComp
					key={album.id}
					{...album}
					type={'Album'}
					isActive={album?.id === clickedAlbum?.id}
					itemNameMaxLength={ALBUM_NAME_LENGTH}
					editItemPopupTitle="Edit Album Name"
					editItemPopupPlaceholder="New Album Name"
					deleteItemPopupTitle="Are you sure?"
					deleteItemPopupDetail="This will delete all photos inside this album."
					isBeingDeleted={deletingAlbums.indexOf(album.id) !== -1}
					editItem={(newName) => {
						albumNameEdited(sortIndex, visibleEvent, newName, album);
					}}
					deleteItem={() => deleteAlbum(sortIndex, visibleEvent, album)}
				/>
			</div>
		));

		const SortableList = SortableContainer(({ albums }) => {
			return (
				<Col type="flex" style={{ marginTop: '6px' }}>
					{albums.map((album, index) => (
						<SortableItem key={`item-${album.id}`} index={index} sortIndex={index} album={album} />
					))}
					{/*uncomment below when sort bug fixed*/}
					{/* <div style={{ marginTop: '10px', marginBottom: '20px' }}>
						Drag & drop any album to re-order albums
					</div> */}
				</Col>
			);
		});

		return (
			//change it to 200 ms when sort bug fixed
			<SortableList pressDelay={200} albums={albums} onSortEnd={this.onSortEnd} />
		);
	};

	onSortEnd = async ({ oldIndex, newIndex }) => {
		if (oldIndex === newIndex) return;
		try {
			await this.props.albumSortOrderChanged(oldIndex, newIndex);
		} catch (e) {
			message.error(e.response.body.message);
		}
	};

	render() {
		let {
			albums,
			createAlbum,
			onGuestImageClick,
			visibleEvent,
			guestImageCount,
			guestUploadsLoading
		} = this.props;

		return (
			<>
				<Col type="flex">
					{!this.state.isMobile && (
						<div
							className="event-home-menu-label"
							style={{ marginLeft: '15px', marginBottom: '10px' }}>
							<SquareLibrary color="#6F4898" />

							<h4 style={{ marginLeft: '10px' }}>ALBUMS</h4>
						</div>
					)}
					<TextFieldButtonComp
						textFieldUpdated={createAlbum}
						placeholder="Add new album"
						actionButton="Add"
					/>
					{albums !== undefined ? this.SortableAlbums(albums) : null}
				</Col>
				{!guestUploadsLoading && (visibleEvent?.isGuestUploadEnabled || guestImageCount > 0) && (
					<Col
						style={{ marginBottom: '2px', marginTop: '30px', cursor: 'pointer' }}
						onClick={(e) => {
							onGuestImageClick(visibleEvent, {
								isGuestUploads: true,
								name: 'Guest Uploads',
								id: 'guestUploads'
							});
						}}>
						<div className="event-home-photos-title">
							<div>
								<h2 style={{ marginBottom: '2px', marginTop: '2px' }}>Guest Uploads</h2>
							</div>
						</div>
					</Col>
				)}
			</>
		);
	}
}

AlbumListComp.propTypes = {
	albums: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired
		}).isRequired
	),
	onAlbumClick: PropTypes.func.isRequired,
	onGuestImageClick: PropTypes.func.isRequired,
	createAlbum: PropTypes.func.isRequired,
	albumNameEdited: PropTypes.func.isRequired,
	deleteAlbum: PropTypes.func.isRequired,
	visibleEvent: PropTypes.object.isRequired,
	albumSortOrderChanged: PropTypes.func.isRequired,
	deletingAlbums: PropTypes.arrayOf(PropTypes.string).isRequired
};
export default AlbumListComp;
