import { connect } from 'react-redux';
import DashboardComp from '../components/DashboardComp';
import { replace } from 'connected-react-router';
import Purchase from '../pouchDB/models/Purchase';
import Event from '../pouchDB/models/Event';
import { updateMyEventsMAction } from '../actions/pouchDBActions';
import Wallet from '../pouchDB/models/Wallet';
import Product, { PRODUCT_TYPE } from '../pouchDB/models/Product';
import Order from '../pouchDB/models/Order';

const mapStateToProps = (state) => ({
	userId: state.auth.userId,
	email: state.auth.email,
	phone: state.auth.phone,
	whitelabelId: state.auth.whitelabelId
});

const mapDispatchToProps = (dispatch) => ({
	fetchPurchasedPacks: async (userId, whitelabelId, consistent) => {
		return await Purchase.fetchPurchases(userId, whitelabelId, consistent);
	},
	createEvent: async (userId, name, packId, purchaseId) => {
		return await Event.createEvent(userId, name, packId, purchaseId);
	},
	eventCreated: async () => {
		await dispatch(updateMyEventsMAction(1, true));
		dispatch(replace('/events'));
	},
	fetchBalance: async (userId) => {
		return await Wallet.fetchBalance(userId);
	},
	fetchWalletProduct: async (userId) => {
		return await Product.fetchProducts(userId, PRODUCT_TYPE.wallet);
	},
	placeRechargeOrder: async (userId, orderItems, rechargeAmount) => {
		return await Order.placeOrder(userId, orderItems, false, false, rechargeAmount);
	},
	confirmOrder: async (userId, orderId, razorPayPaymentId, signature, eventId) => {
		return await Order.confirmOrder(userId, orderId, razorPayPaymentId, signature, eventId);
	},
	redeem: async (userId, code) => {
		return await Wallet.redeem(userId, code);
	}
});

const Dashboard = connect(mapStateToProps, mapDispatchToProps)(DashboardComp);

export default Dashboard;
