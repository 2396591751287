import {
  ACTION_LOGIN_SUCCESS,
  ACTION_LOGIN_FAILED,
  ACTION_LOGIN_START_ON_BUTTON_TAP,
  ACTION_LOGGED_OUT, ACTION_CLEAR_LOGIN_ERR
} from '../actions/action_types'

const auth = (state = {
  loginFailed: false,
  isLoggingIn: false,
  isLoggedIn: false,
}, action) => {
  switch (action.type) {
    case ACTION_LOGIN_START_ON_BUTTON_TAP:
      return {
        ...state,
        isLoggingIn: true,
        loginFailed: false,
        isLoggedIn: false,
        lastError: undefined
      }
    case ACTION_CLEAR_LOGIN_ERR:
      return {...state, lastError: undefined}
    case ACTION_LOGIN_SUCCESS:
      return {
        ...state,
        isLoggingIn: false,
        isLoggedIn: true,
        token: action.token,
        userId: action.userId,
        loginFailed: false,
        lastError: undefined,
        name: action.name,
        email: action.email,
        whitelabelId: action.whitelabelId,
        phone: action.phone,
        isWhitelabelAdmin:action.isWhitelabelAdmin
      }
    case ACTION_LOGIN_FAILED:
      return {
        ...state,
        isLoggingIn: false,
        isLoggedIn: false,
        token: undefined,
        userId: undefined,
        name: undefined,
        email: undefined,
        whitelabelId: undefined,
        phone: undefined,
        loginFailed: true,
        lastError: action.lastError
      }
    case ACTION_LOGGED_OUT:
      return {
        ...state,
        isLoggingIn: false,
        isLoggedIn: false,
        token: undefined,
        userId: undefined,
        name: undefined,
        email: undefined,
        whitelabelId: undefined,
        phone: undefined,
        loginFailed: false,
        lastError: undefined
      }
    default:
      return state;
  }
}

export default auth
