import React, { Component } from 'react';
import { ReactComponent as FBLoginSVG } from '../svg/FacebookLogin.svg';
import { loginStarted, needsLogin, logout, loginFailed, login } from '../actions/loginActions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { bindAll } from 'lodash';
import { store } from '../index';

const LOGIN_OPTIONS = {
	scope: 'email,public_profile',
	return_scopes: true
};

const FETCH_FIELDS = 'name, email';

class FBLoginComp extends Component {
	eventFbObjectReady = 'FBObjectReady';

	constructor(props) {
		super(props);
		bindAll(this, ['loginWithFacebook', 'fbLoginHandler']);
	}

	loginWithFacebook = () => {
		const FB = window.FB;
		if (!FB) return;

		const { loginClicked } = this.props;
		console.log('Loggin with Facebook.');
		loginClicked();
		FB.login(this.fbLoginHandler, LOGIN_OPTIONS);
	};

	fbLoginHandler = (response) => {
		const { loginFailedCompAction, fbConnectedCompAction } = this.props;
		if (response.status && response.status === 'connected') {
			window.FB.api('/me', { fields: FETCH_FIELDS }, (me) => {
				console.log(response);
				Object.assign(me, response.authResponse);
				fbConnectedCompAction(me.accessToken, me.userID, me.name, me.email);
			});
		} else {
			console.log(response);
			loginFailedCompAction(' ');
		}
	};

	render = () => {
		return (
			<Spin
				spinning={this.props.isLogging}
				onClick={this.loginWithFacebook}
				style={{ cursor: 'pointer' }}>
				<FBLoginSVG style={{ width: '220px', height: '40px', cursor: 'pointer' }} />
			</Spin>
		);
	};
}

FBLoginComp.propTypes = {
	loginClicked: PropTypes.func.isRequired,
	isLogging: PropTypes.bool.isRequired,
	needsLoginCompAction: PropTypes.func.isRequired,
	fbConnectedCompAction: PropTypes.func.isRequired,
	loginFailedCompAction: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
	isLogging: state.auth.isLoggingIn
});

const mapDispatchToProps = (dispatch) => ({
	loginClicked: () => {
		dispatch(loginStarted());
	},
	needsLoginCompAction: () => {
		dispatch(needsLogin());
	},
	fbConnectedCompAction: (token, userId, fbName, fbEmail) => {
		dispatch(login(token));
	},
	loginFailedCompAction: (error) => {
		dispatch(loginFailed(error));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(FBLoginComp);

export const logoutNow = () => {
	store.dispatch(logout(window.FB));
};
